<template>
  <div>
    <v-container class="mb-8 pb-8 gradient">
      <v-row justify="center" class="mt-6 mb-6">
        <v-img
          src="/static/monplanning.svg"
          max-width="250"
          max-height="50"
        />
      </v-row>
      <v-row justify="center">
        <v-col sm="8">
          <h1 class="font-weight-medium mt-4 text-h4 text-center">
            Mot de passe oublié ?
          </h1>
          <h2 class="mt-4 text-body-2 text-center font-weight-medium">
            Pas d'inquiétude, on s'occupe de vous ! Merci de suivre notre
            procédure de récupération de compte.
          </h2>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
          <div v-if="token">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{
                  $helpers.string.capitalizeI18N("reset-password")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert
                  v-if="message"
                  :type="successful ? 'success' : 'error'"
                  >{{ message }}</v-alert
                >
                <v-form
                  ref="form"
                  @submit.prevent="handleResetPasswordFromToken"
                >
                  <v-text-field
                    :type="showPassword ? 'text' : 'password'"
                    :label="
                      $helpers.string.capitalizeI18N('new-password', {
                        required: true,
                      })
                    "
                    v-model="password"
                    name="password"
                    prepend-icon="lock"
                    autofocus
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="$helpers.rule.notEmpty()"
                  />
                  <v-btn
                    color="primary"
                    @click="handleResetPasswordFromToken"
                    :disabled="loading"
                    block
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>{{ $t("continue") }}</span>
                  </v-btn>
                  <div class="mt-4 text-caption">
                    * {{ $t("required-fields") }}
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
          <div v-else>
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{
                  $helpers.string.capitalizeI18N("reset-password-title")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert
                  v-if="message"
                  :type="successful ? 'success' : 'error'"
                  >{{ message }}</v-alert
                >
                <v-form ref="form" @submit.prevent="handleResetPassword">
                  <v-text-field
                    :label="
                      $helpers.string.capitalizeI18N('email', {
                        required: true,
                      })
                    "
                    v-model="email"
                    prepend-icon="mdi-email"
                    type="email"
                    :rules="$helpers.rule.notEmpty()"
                  />
                  <v-btn
                    color="primary"
                    @click="handleResetPassword"
                    :disabled="loading"
                    block
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>{{ $t("recover-my-password") }}</span>
                  </v-btn>
                  <div class="mt-4 text-caption">
                    * {{ $t("required-fields") }}
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col>
                      <div>
                        <v-btn
                          class="font-weight-medium"
                          text
                          to="/admin/login"
                          color="primary"
                          >{{ $t("connection") }}</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      email: null,
      loading: false,
      message: "",
      successful: false,
      showPassword: false,
      password: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.adminauth.user;
    },
    token() {
      return this.$route.query.token;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$helpers.router.push("admin.dashboard");
    }
  },
  methods: {
    handleResetPassword() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.$store.dispatch("adminauth/resetPassword", this.email).then(
        () => {
          this.loading = false;
          this.successful = true;
          this.message = this.$t("reset-password-confirmation-email");
        },
        (error) => {
          this.loading = false;

          this.$helpers.snackbar.handleError(error);
        }
      );
    },
    handleResetPasswordFromToken() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.$store
        .dispatch("adminauth/resetPasswordFromToken", {
          token: this.token,
          password: this.password,
        })
        .then(
          () => {
            this.loading = false;

            this.$router.push({
              path: this.$helpers.router.getI18NRoute("admin.login"),
              query: { resetpassword: 1 },
            });
          },
          (error) => {
            this.loading = false;

            this.$helpers.snackbar.handleError(error);
          }
        );
    },
  },
};
</script>